// Webpack Imports
// import 'bootstrap';
import 'bootstrap';
import $ from 'jquery';
import hongkong from 'hongkong';
import 'jquery-confirm';
import 'tilt.js';
import './js/player.js';

(function ($) {
	'use strict';

	// JQuery fallback: add title attribute from placeholder
	$('input, textarea').attr('title', function () {
		return $(this).attr('placeholder');
	});
	
	// Focus Search if Searchform is empty
	$('.search-form').on('submit', function (e) {
		var search = $('#s');
		if (search.val().length < 1) {
			e.preventDefault();
			search.focus();
		}
	});

	jQuery.readyException = function( error ) {
  	console.error( error );
	};

	// body screen width and height
	var doc_width = $(document).width();
	var doc_height = $(document).height();
	var body_width = $("body").width();
	var check_body_width = $("body").width();
	var body_height = $("body").height();
	var hash_zlatni_gros = "";
	var hash_video = "#video";
	var hash_istorijat = "#istorijat";
	var hash_onama = "#onama";
	var hash_galerija = "#galerija";
	var hash_ponuda = "#ponuda";
	var hash_kontakt = "#kontakt";
	$("#video").bind("onshow", function(){
		window.location.hash = hash_video;
	});

$("strong.info-width-height").html(body_width+" w:h "+body_height);

	var nav_button = $('.nav-mobi-hidden');
	// var nav = $("#navbar").hide();
	var vizfr = $("#viz-fr");
	var vizsc = $("#viz-sc");
// vizfr.removeClass( "visible" ).addClass( "invisible" );
// vizsc.removeClass( "invisible" ).addClass( "visible" );

	/* Trigger click after 6 secunds */
	// setTimeout(function() {

 //    $("#viz-fr").trigger('click');

 //  }, 5600);

	// $("#viz-fr").one("click", function() {
	// 	vizfr.removeClass( "visible" ).addClass( "invisible" );
	// 	vizsc.removeClass( "invisible" ).addClass( "visible" );
		// nav = $("#navbar").show();
		// $("#change-style").removeAttr("href").attr("href", "css2_2.css");
	  
	  // $('#video-coffe')[0].play();
	  // $('video')[0].play();
		// $("#video-coffe").on("show", function(){
	 //    $(this)[0].play();
	 //  });
	// });
	function checkBodyWidthAndHeightOnLoad(event) {
		// console.log(event.target);
	}
	checkBodyWidthAndHeightOnLoad("load");
	function rESizeS_if() {
		$(window).on("resize", function() {
			let vh = window.innerHeight * 0.01;
			// console.log(vh);
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty('--vh', `${vh}px`);

	   	var gal_width = $(".gallery").width();
	   	var res_css_file = "";
	   	if ($(this).width() <= "340") {
	   		$(".change-to-this-img").width(gal_width/4);
	   	}
   	});
	}
	if (body_width == check_body_width) {

	} else if (body_width < check_body_width) {
		rESizeS_if();
	} else if (body_width > check_body_width) {
		rESizeS_if();
	}
$(window).on("load", function() {
	window.location.hash = hash_zlatni_gros;
});
	$(window).scroll(function() {
    if ($(this).scrollTop()) {
        $('#to-top').fadeIn();
    } else {
        $('#to-top').fadeOut();
    }
	});
	var socialWrapper = $(".social-wrapper i");
	$(".bg-colo.black-m-gold").click(function() {
	  // $(this).addClass("bg-active");
	  var bodyClass = $("body").attr("class");
	  $("body").removeClass(bodyClass);
	  $("body").addClass("body-bg-black-gold");
	  if (socialWrapper.attr("class") != "social-light") {
	  	socialWrapper.addClass("social-light");
	  }
	  if ($(this).attr("class") === "bg-colo black-m-gold shade-black") {
	  } else if ($(this).attr("class") === "bg-colo black-m-gold") {
		  $(this).addClass("shade-black");
		  $(this).siblings().addClass("shade-black");
	  }
	  // console.log($(this).attr("class"));
	});
	$(".bg-colo.gold-m-black").click(function() {
	  // $(this).addClass("bg-active");
	  var bodyClass = $("body").attr("class");
	  $("body").removeClass(bodyClass);
	  $("body").addClass("body-bg-gold-black");
	  $(this).addClass("shade-black");
	  $(this).siblings().addClass("shade-black");
	  socialWrapper.removeClass("social-light");
	  // console.log($(this).attr("class"));
	});
	$(".bg-colo.black-m-black").click(function() {
	  // $(this).addClass("bg-active");
	  var bodyClass = $("body").attr("class");
	  $("body").removeClass(bodyClass);
	  $("body").addClass("body-bg-white");
	  $(this).removeClass("shade-black");
	  $(this).siblings().removeClass("shade-black");
	  socialWrapper.removeClass("social-light");
	  // console.log($(this).attr("class"));
	});
	$(".bg-colo.gold-m-gold").click(function() {
	  // $(this).addClass("bg-active");
	  var bodyClass = $("body").attr("class");
	  $("body").removeClass(bodyClass);
	  $("body").addClass("body-bg-gold");
	  $(this).removeClass("shade-black");
	  $(this).siblings().removeClass("shade-black");
	  socialWrapper.removeClass("social-light");
	  // console.log($(this).attr("class"));
	});
	$("#to-top").click(function() {
	  $("html, body").animate({scrollTop: 0}, 1000);
	  window.location.hash = hash_zlatni_gros;
	});
	var nav_height = $("nav#navbar").height();
	var conteinerfluid = $(".container-fluid").height();
	var panel_margin_bottom = $(".panel").css("margin-bottom");
	panel_margin_bottom = parseInt(panel_margin_bottom);
	var reclam_image = $(".img-left-right").height();
	// var header_height = $("#video").height() + nav_height + 20;
	// var header_height = $("#video").height() + nav_height;
	var header_height = $("div#video").height();
	var section_i_height = $("div#istorijat").height() + header_height + panel_margin_bottom;
	var section_o_height = $("div#onama").height() + section_i_height + panel_margin_bottom;
	var section_g_height = $("div#galerija").height() + section_o_height;// + section_o_height + panel_margin_bottom;
	var galery_height = $("div#galerija").height();
	var section_height = section_o_height + $("div#galerija").height() + reclam_image + nav_height + (panel_margin_bottom * 4);
	// var section_p_height = $("#grosponuda").height() + section_g_height + reclam_image;
	// var section_p_height = $("div#grosponuda").height() + section_g_height - (panel_margin_bottom * 1);
	var section_p_height = section_g_height + galery_height + (panel_margin_bottom * 8);
	var footer_height = $("#kontakt").height() + section_p_height + (panel_margin_bottom * 4);
  // console.log(doc_height);
  // console.log(header_height);
  // console.log(section_i_height);
  // console.log(section_o_height);
  // console.log(section_g_height);
  // console.log(section_p_height);
  // console.log(section_height);
  // console.log(footer_height);
  // header_height = parseInt(header_height);
	$(".istorijat-link").click(function() {
	  $("html, body").animate({scrollTop: header_height}, 1000);
	 });
	$(".onama-link").click(function() {
	  $("html, body").animate({scrollTop: section_i_height}, 1000);
	 });
	$(".galerija-link").click(function() {
	  $("html, body").animate({scrollTop: section_o_height}, 1000);
	 });
	$(".grosponuda-link").click(function() {
	  $("html, body").animate({scrollTop: section_p_height}, 1000);
	 });
	$(".kontakt-link").click(function() {
	  $("html, body").animate({scrollTop: footer_height}, 1000);
	 });

	$('a[href^="#"]').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
      event.preventDefault();
      $('html, body').stop().animate({
          scrollTop: target.offset().top - 20
      }, 1000);
    }
	});

$( ".change-to-this-img.selected" ).each(function(index) {
	// $(".horizontal-gallery-item:first-child").show();
    $(this).on("click", function(){
	// $(".horizontal-gallery-item:first-child").hide();
        // For the boolean value
        var boolKey = $(this).data('selected', true); 
        // console.log(boolKey);
        // For the mammal value
        var mammalKey = $(this).attr('id');
        // console.log(mammalKey);
        var mammalKeyClass = "."+mammalKey;
        $(mammalKeyClass).show();
        $(mammalKeyClass).siblings().data('selected', false);
        $(mammalKeyClass).siblings().hide();
				// $( ".horizontal-gallery-item:nth-child(1n)" ).hide();
        // console.log(mammalKeyClass);
    });
		if (!$(this).data('selected') === true){
			$("."+$(this).attr('id')).hide();
		} else if ($(this).data('selected') === true){
			$("."+$(this).attr('id')).show();
		}
});


	$("#btn-200").on("click", function(e) {
		// var theButton = $(this);
		e.preventDefault();

		$.confirm({
		    title: '<h3>Narudžbenica za Zlatni groš premijum kafu</h3>',
		    type: 'default',
		    theme: 'bootstrap',
    		draggable: false,
		    bgOpacity: 0,
		    closeIcon: true,
		    closeIconClass: 'glyphicon glyphicon-remove-circle',
		    columnClass: 'col-xl-4 col-xl-offset-4 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1',
    		useBootstrap: true,
    		animationSpeed: 1000,
		    scrollToPreviousElement: true,
		    scrollToPreviousElementAnimate: true,
    		backgroundDismissAnimation: 'glow',
		    content: '<form id="order-form">'
		    + '<div class="confirm-customer-data-div">\
		    <label class="label input-label-name" for="customer-name">\
		    		<input id="customer-name" type="text" data-name="$(this).val()" class="form-control customer_name" name="customer_name" value="" placeholder="Vaše ime i prezime" required>\
		    	</label><br>\
		    	<label class="label input-label-address" for="address">\
		    		<input id="FormAdressInput" type="text" data-address="$(this).val()" class="form-control address" name="address" value="" placeholder="Adresa: ulica, broj i poštanski broj grada" required>\
		    	</label><br>\
		    	<label class="label input-label-email" for="email">\
		    		<input id="FormEmailInput" type="email" class="form-control email" name="email" value="" placeholder="Email: name@example.com" required>\
		    	</label><br>\
		    	<label class="label input-label-phone" for="telephone">\
		    		<input id="customer-phone" type="number" class="form-control telefon" placeholder="broj telefona" required>\
		    	</label>\
		    </div>\
				<div class="package-type">\
					<div class="pkg-type-header">Veličina pakovanja kesice</div>\
					<div class="span-pkg-type">\
						<label for="radio-100" class="label package-label">\
							<input id="radio-100" class="radio" name="radio" type="radio" value="100" checked> 100 gr\
						</label>\
						<label for="radio-200" class="label package-label">\
							<input id="radio-200" class="radio" name="radio" type="radio" value="200"> 200 gr\
						</label>\
						<label for="radio-1" class="label package-label">\
							<input id="radio-1" class="radio" name="radio" type="radio" value="1000"> 1 kg\
						</label>\
					</div>\
				</div>\
		    <div class="confirm-num-price">\
		    	<div class="confirm-pieces">\
			    	<label class="label piece-label" for="exampleFormControlSelect1"> Količina: <br>\
					    <select id="exampleFormControlSelect1" class="form-control-half">\
					      <option value="0.0">0</option>\
					      <option value="1">1 kg</option>\
					      <option value="2">2 kg</option>\
					      <option value="3">3 kg</option>\
					      <option value="4">4 kg</option>\
					      <option value="5">5 kg</option>\
					      <option value="6">6 kg</option>\
					      <option value="7">7 kg</option>\
					      <option value="8">8 kg</option>\
					      <option value="9">9 kg</option>\
					      <option value="10">10 kg</option>\
					    </select>\
						</label>\
					</div>\
				<div class="confirm-none"></div>\
				<div class="confirm-price">\
					<label class="label price-label" for="price">Iznos<br>\
						<input id="price" type="number" class="form-control-half" placeholder="0" value="0">\
			    </label>\
			   </div>\
		    </div>\
		    <div class="confirm-customer-message">\
		    	<label class="label message-label" for="message">\
		    		<textarea id="message" class="form-control" placeholder="Ostavite poruku"></textarea>\
		    	</label>\
		       </div>\
		    </form><br>',
		    buttons: {
		        confirmButton: {
	        		text: 'Naruči',
	            	confirmButtonClass: 'btn btn-success confirm-button',
	            	keys: ['enter'],
				},
		        cancelButton: {
		        	text: 'Otkaži',
	            	cancelButtonClass: 'btn btn-success cancel-button',
		        	keys: ['esc'],
				},
		    },
    		onOpen: function () {

  				var name = this.$content.find("input#customer-name").val();
				  var address = this.$content.find("input#FormAdressInput").val();
				  var email = this.$content.find("input#FormEmailInput").val();
				  var phone = this.$content.find("input#customer-phone").val();
				  var coffebag = this.$content.find('input[name=radio]:checked').val();
				  // var selected_number = $("#exampleFormControlSelect1").find("option:selected").val();
				  var message = this.$content.find("textarea#message").val();
		      var self = this;
		      var price = this.$content.find("input#price");
				  self.buttons.confirmButton.disable();
		      var selected_number = this.$content.find('select#exampleFormControlSelect1').change(function() {
				  	var selected = $(this).find('option:selected').val();
					
				    if (((selected >= "1") && (selected <= "10")) || ((selected >= 1) && (selected <= 10))) {
		            /* cena je jednaka selektovanom broju pakovanja puta 5 kilograma(1 pakovanje = 5kg) puta cena(npr 1000 dinara) */
		            price = price.val(parseInt(selected)*850);
		            // console.log(price.val());
								self.buttons.confirmButton.enable();
		        		// self.buttons.ok.disable();
				    } else if (((selected > "0.0") && (selected < "1")) || ((selected > 0.0) && (selected < 1))) {
		            /* cena je jednaka selektovanom broju pakovanja puta 5 kilograma(1 pakovanje = 5kg) puta cena(npr 1000 dinara) */
		            price = price.val(parseFloat(selected)*850);
		            // console.log(price.val());
								self.buttons.confirmButton.enable();
		        		// self.buttons.ok.disable();
				    } else if ((selected == "0.0") || (selected == 0.0)) {
					    	$(this).val($.data(this, "0.0"));
					    	price = price.val(parseFloat(selected)*850);
			        	self.buttons.confirmButton.disable();
				    }
						
					});
	        this.$content.find('.btn.confirm-button').click(function(){
	        	if (!$("input").empty()) {

	        	}
	          // self.$content.find('input#customer-name').val();
	          self.buttons.confirmButton.enable();
	        });
	        this.$content.find('.btn.btn-success.cancel-button').click(function(){
	        	$(".jconfirm").modal("hide");
	        });
		    },
    		onAction: function(confirmButton){
    	let allData = [];
		if ((this.$content.find("input#customer-name").val() !== "") && (this.$content.find("input#FormAdressInput").val() !== "") && (this.$content.find("input#FormEmailInput").val() !== "") && (this.$content.find("input#customer-phone").val() !== "") && ($("#exampleFormControlSelect1").find("option:selected").val() !== "") && ($("#exampleFormControlSelect1").find("option:selected").val() !== "0") && ($("#exampleFormControlSelect1").find("option:selected").val() !== 0)) {
	        
            $.ajax({
	            url: 'inc/send_mail.php',
	            method: 'post',
	            data: {name:this.$content.find("input#customer-name").val(), address:this.$content.find("input#FormAdressInput").val(), email:this.$content.find("input#FormEmailInput").val(), phone:this.$content.find("input#customer-phone").val(), coffebag:this.$content.find('input[name=radio]:checked').val(), number:this.$content.find("#exampleFormControlSelect1").find("option:selected").val(), message:this.$content.find("textarea#message").val()},
	                success: function (data) {
	                    if (data) {
	                        // console.log(data);
	                        allData.push(data);
	                        // console.log(allData);
	                        $(".jconfirm").modal('hide');
	                        $(".ajax-paragraf").html(allData);
    						window.location.hash = "#grosponuda";   
	                    } else {
	                        // console.log(allData);
	                    	console.log("Sending failed, Please try again.");
	                    	$(".ajax-paragraf").html(allData);
	                    } 
	                        console.log(allData);   
	                },
	                error: function (xhr, status, error) {     
	                    console.log("error 420");
	                    // console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText + " ");
	                    $(".ajax-paragraf").html("Naručivanje nije uspelo!");   
	                }
               });
        } else {
              if (this.$content.find("input#customer-name").val() == "") {
                  this.$content.find("#customer-name").trigger('focus');
                  this.buttons.confirmButton.disable();
                  this.$content.find("input#customer-name").val("Unesite vaše ime i prezime!!!");
                  console.log("Unesite vaše ime i prezime!!!");
              }
              if (this.$content.find("input#FormAdressInput").val() == "") {
                  this.$content.find("#FormAdressInput").trigger('focus');
                  this.buttons.confirmButton.disable();
                  this.$content.find("input#FormAdressInput").val("Unesite vašu adresu!!!");
                  console.log("Unesite vašu adresu!!!");
              }
              if (this.$content.find("input#FormEmailInput").val() == "") {
                  this.$content.find("#FormEmailInput").trigger('focus');
                  this.buttons.confirmButton.disable();
                  console.log("Unesite email adresu!!!");
              }
              if (this.$content.find("input#customer-phone").val() == "") {
                  this.$content.find("#customer-phone").trigger('focus');
                  this.buttons.confirmButton.disable();
                  console.log("Unesite broj telefona!!!");
              }
              if (($("#exampleFormControlSelect1").find("option:selected").val() == "") && ($("#exampleFormControlSelect1").find("option:selected").val() == "0") && ($("#exampleFormControlSelect1").find("option:selected").val() == 0)) {
                  this.$content.find('#exampleFormControlSelect1 > option').trigger('focus');
                  // this.buttons.ok.disable();
                  console.log("Selektujte koliko paketa želite!!!");
              }
              // $(".ajax-paragraf").html("&nbsp; &nbsp; Podatci: ");
              console.log(this.$content.find("input#customer-name").val());
	          console.log(this.$content.find("input#FormAdressInput").val());
	          console.log(this.$content.find("input#FormEmailInput").val());
	          console.log(this.$content.find("input#customer-phone").val());
	          console.log(this.$content.find('input[name=radio]:checked').val());
	          console.log(this.$content.find("textarea#message").val());
	          console.log($("#exampleFormControlSelect1").find("option:selected").val());
	          console.log(this.$content.find("input#price").val());
              console.log(" button disabled !!! ");
              console.log("PLEASE FILL THE FORM UP!!!");
              this.buttons.confirmButton.disable();
          }
    	},
		});
		
	});
$(".jconfirm").on("show", function() {
	var ofgp = $("#grosponuda").offset().bottom;
	$(".jconfirm").css("transform", "translate(0%, -"+ofgp+"px)");
});
	// hongkong($);
	$.hongkong();

	const tilt = $('.js-tilt').tilt();

    $('.js-destroy').on('click', function () {
        const element = $(this).closest('.js-parent').find('.js-tilt');
        element.tilt.destroy.call(element);
    });

    $('.js-getvalue').on('click', function () {
        const element = $(this).closest('.js-parent').find('.js-tilt');
        const test = element.tilt.getValues.call(element);
        // console.log(test[0]);
    });

    $('.js-reset').on('click', function () {
        const element = $(this).closest('.js-parent').find('.js-tilt');
        element.tilt.reset.call(element);
    });

    $('.landing-tilt').tilt({
	    reset: true,
    	scale: 2
	});

    $('.gros-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

    $('.istorijat-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

    $('.onama-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

    $('.ponuda-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

    $('.gallery-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

	$('.kontakt-tilt').tilt({
	    reset: true,
    	scale: 1.2
	});

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	// let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	// document.documentElement.style.setProperty('--vh', `${vh}px`);
	/* @function src page.location */
	// function pageLocation(){
	// 	var wl_hash = window.location.hash;
	// 	var ar_loca = ["#video","#istorijat","#onama","#ponuda","#galerija","#kontakt"];
	// 	$(window).on("scroll",fucntion(){
	//       if(($(this).scrollTop() > 100) && ($(this).scrollTop() < 200)){
	//           window.location.hash = ar_loca[1];
	//       }else{
	//           window.location.hash = "";
	//       }
	//   };
	// }
	

}(jQuery));


// (function () {
// 	'use strict';

// var player;
// function onYouTubeIframeAPIReady() {
//   player = new YT.Player( 'player', {
//     events: { 'onStateChange': onPlayerStateChange }
//   });
// }

// function onPlayerStateChange(event) {
//   switch(event.data) {
//     case 0:
//       record('video ended');
//       break;
//     case 1:
//       record('video playing from '+player.getCurrentTime());
//       break;
//     case 2:
//       record('video paused at '+player.getCurrentTime());
//       break;
//   }
// }
// 2. This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
var player;
function onYouTubeIframeAPIReady() {
player = new YT.Player('player', {
  // height: '390',
  // width: '640',
  videoId: 'KvofX8c1Xl8',
  playerVars: { 
    'autoplay': 1, 
    'controls': 1, 
    // "enablejsapi":1,
    'host': 'http://localhost/zlatni_gros/'
    // 'host': 'https://zlatnigros.rs/'
  },
  events: {
    // 'onVisible':onPlayerReady,
    'onReady': onPlayerReady,
    // 'onStateChange': onClickTogglePlay
    'onStateChange': onPlayerStateChange,
    /*,
    'onError': onPlayerError*/
  }
});
}

// 4. The API will call this function when the video player is ready.
	function onPlayerReady(event) {
		// setTimeout(event.target.playVideo(), 6600);
		event.target.playVideo();
		console.log(player.getPlayerState());
		document.getElementById('player').style.borderColor = '#FF6D00';
		document.getElementById('player').style.boxShadow =  "0 0 9px 9px #FF6D00";
	}

var done = false;
  function onPlayerStateChange(event) {
    changeBorderColor(event.data);console.log(player.getPlayerState());
    
    if (event.data == YT.PlayerState.PLAYING && !done) {
      setTimeout(stopVideo, 42000);
      changeBorderColor(event.data);
      done = true;
    } else {
    	changeBorderColor(event.data);
    }
    
  }

  function stopVideo() {
    player.stopVideo();
  }

  function changeBorderColor(playerStatus) {
    var color;
    if (playerStatus == -1) {
      color = "#37474F"; // unstarted = gray
    } else if (playerStatus == 0) {
      color = "#FFFF00"; // ended = yellow
    } else if (playerStatus == 1) {
      color = "#33691E"; // playing = green
    } else if (playerStatus == 2) {
      color = "#DD2C00"; // paused = red
    } else if (playerStatus == 3) {
      color = "#AA00FF"; // buffering = purple
    } else if (playerStatus == 5) {
      color = "#FF6DOO"; // video cued = orange
    }
    if (color) {
      document.getElementById('player').style.borderColor = color;
      document.getElementById('player').style.boxShadow =  "0 0 9px 9px "+color;
    }
  }

function record(data){
    // Do what you want with your data
  var p = document.createElement("p");
  p.appendChild(document.createTextNode(data));
  document.body.appendChild(p);
}

// }());